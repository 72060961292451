<template>
	<div class="FM_page-404">
		<h1 class="text-light">404</h1>
	</div>
</template>

<script>
	export default {
		name: "PageNotFound"
	}
</script>